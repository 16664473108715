import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {DrawSVGPlugin} from "../libs/DrawSVGPlugin.min";

gsap.registerPlugin(ScrollTrigger,DrawSVGPlugin);

function cpIntro(winHeight) {


    let isMobile = false;
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        isMobile = true;
    }

    let delayG = 0;
    gsap.utils.toArray('#intro_logo svg').forEach((box) => {
        gsap.to(box, {
            alpha:1,
            y:0,
            delay: delayG,
            duration: 1,
            onComplete: function(){
                gsap.to('.circle-anim', {
                    alpha: 1,
                    duration: 1,
                });
            }
        })
        delayG += 0.4;
    });


    let tl = gsap.timeline({
        scrollTrigger: {
            trigger: ".cp-hero-banner",
            toggleActions: 'play pause play reverse',
            start: 'top 0%',
            end: "bottom 100%",
            scrub: 1,
            pin: ".pin-hero"
        },
    });
    let bottomEllipse = '-50%';
    let widthEllipse = '50%';
    if(isMobile){
        bottomEllipse = '0%';
        widthEllipse = '100vh';
    }
    tl.add('scrollIntro')
        .to('.circle-anim .ellipse',{
            scale: 3.2,
            rotation: '90deg',
            right: '50%',
            width: widthEllipse,
            x: '50%',
            y: '-50%',
            bottom: bottomEllipse,
        }, 'scrollIntro')
        .to('.circle-anim .ellipse-inner', {
            rotation: '-110deg',
        }, 'scrollIntro')
        .to('.circle-anim  span', {
            bottom: '100%',
            right: '80%',
        }, 'scrollIntro')


    // On intro out => anim logo

    let tl2 = gsap.timeline({
        scrollTrigger: {
            trigger: "#cp2",
            toggleActions: 'play pause play reverse',
            start: 'top 130%',
            end:'+=800',
            scrub: 1
        },
    });


    let tl3 = gsap.timeline({
        scrollTrigger: {
            trigger: "#cp2",
            toggleActions: 'play pause play reverse',
            start: 'top 150%',
        },
    });
    if(!isMobile){
        tl3.to('#menu_scroll',{
            bottom: '30px',
            y: 0
        })
    }


    tl2.add('outIntro')
        .to('#id_1',{
            x : '-200px'
        },'outIntro')
        .to('#id_2',{
            x : '75px'
        },'outIntro')
        .to('#id_3',{
            x : '-100px'
        },'outIntro')

}

export default cpIntro;
