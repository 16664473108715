import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function initAccordion(winHeight){

    let tl2 = gsap.timeline({
        scrollTrigger: {
            trigger: ".titles-faq",
            toggleActions: 'play pause play reverse',
            start: 'top 100%',
            end:'+='+(winHeight / 2),
            scrub: 1
        },
    });

    tl2.add('outIntro')
        .fromTo('.title-left .title-move',{
            x: '-50%'
        },{
            x : '0%'
        },'outIntro')
        .fromTo('.title-right .title-move',{
            x: '50%'
        },{
            x : '0%'
        },'outIntro')

    let groups = gsap.utils.toArray(".accordion-group");
    let menus = gsap.utils.toArray(".accordion-menu");
    let menuToggles = groups.map(createAnimation);

    menus.forEach((menu) => {
        menu.addEventListener("click", () => toggleMenu(menu));
    });

    function toggleMenu(clickedMenu) {
        menuToggles.forEach((toggleFn) => toggleFn(clickedMenu));
    }

    function createAnimation(element) {
        let menu = element.querySelector(".accordion-menu");
        let box = element.querySelector(".accordion-content");
        let minusElement = element.querySelector(".accordion-minus");
        let plusElement = element.querySelector(".accordion-plus");

        gsap.set(box, { height: "auto" });

        let animation = gsap
            .timeline()
            .from(box, {
                height: 0,
                duration: 0.5,
                ease: "power1.inOut"
            })
            .from(minusElement, { duration: 0.2, autoAlpha: 0, ease:"none" }, 0)
            .to(plusElement, { duration: 0.2, autoAlpha: 0, ease:"none" }, 0)
            .reverse();

        return function (clickedMenu) {
            if (clickedMenu === menu) {
                animation.reversed(!animation.reversed());
            } else {
                animation.reverse();
            }
        };
    }
}
export default initAccordion;
