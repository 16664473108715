import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function cpGallery(winHeight) {

    let itemGallery = gsap.timeline({
        scrollTrigger: {
            trigger: '.cp-gallery',
            toggleActions: 'play pause play reverse',
            start: 'top 0%',
            end: 'bottom 100%',
            pin: '.cp-gallery > .teq-row',
            scrub: 1
        },
    });

    itemGallery.add('animGallery');
    let iImage = 0;
    let xD,yD;
    gsap.utils.toArray('.gallery-item').forEach( item => {
        const img = item.querySelector('.gallery-item-inner');

        if(img.classList.contains('odd')){
             xD = ['150px', '250px'];
        }else{
             xD = ['-150px','-250px'];
        }

        itemGallery.to(img,{
           alpha:1,
            duration:0.05,
            delay:iImage,
        },'animGallery')
            .to(img,{
                scale:1,
                x:xD[0],
                duration:0.1,
                delay:iImage,
                ease:'none'
        },'animGallery');

        itemGallery.to(img,{
            scale:2,
            duration:0.1,
            x: xD[1],
            filter:'blur(100px)',
            delay:iImage+0.1,
            ease:'none'
        },'animGallery')
            .to(img,{
                alpha:0,
                duration:0.05,
                delay:iImage+0.15,
                ease:'none'
        },'animGallery')
        iImage += 0.1;
    })
}
export default cpGallery;
