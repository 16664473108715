import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {SplitText} from "../libs/SplitText.min";

gsap.registerPlugin(ScrollTrigger,SplitText);

function cpReading() {


    document.fonts.ready.then(function () {
        // SPLIT TEXT
        var split = new SplitText(".splitText", {
            linesClass: 'line',
            wordsClass: "word",
            charsClass: 'letter'
        });

        gsap.utils.toArray('.cp-readingblock').forEach(cp => {
            // reading text
            const lines = cp.querySelectorAll('.line');
            lines.forEach(line => {
                let tlReading = gsap.timeline({
                    scrollTrigger: {
                        trigger: line,
                        toggleActions: 'play pause play reverse',
                        start: 'top 50%',
                        end: "+=50",
                        scrub: 1
                    },
                });

                let letters = line.querySelectorAll('.letter');
                let n = 0;
                let nIncremant = 1 / letters.length;
                letters.forEach(letter => {
                    tlReading.to(letter, {
                        alpha: 1,
                        duration: 0.05,

                    })
                    n += nIncremant
                })
            })
        });

    });


    ScrollTrigger.refresh();
}
export default cpReading;
