import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function cpOeuvre(winHeight) {

    let isMobile = false;
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        isMobile = true;
    }


    let pinVideo = gsap.timeline({
        scrollTrigger: {
            trigger: '.cp-oeuvre',
            toggleActions: 'play pause play reverse',
            start: 'top 0%',
            end: "bottom 70%",
            scrub: 1,
            pin: ".cp-oeuvre >.teq-row",
            anticipatePin: 1
        }
    });
    let moveVideo = gsap.timeline({
        scrollTrigger: {
            trigger: '.cp-oeuvre',
            toggleActions: 'play pause play reverse',
            start: '+=100vh',
            end:"+=200vh",
            scrub: 1,
        }
    });

    let moveLogo = gsap.timeline({
        scrollTrigger: {
            trigger: '.cp-oeuvre',
            toggleActions: 'play pause play reverse',
            start: '+=100vh',
            end:"+=250vh",
            scrub: 1,
        }
    });

    if(!isMobile){
        moveVideo.to('.cp-oeuvre .video-container', {
            x:'-60%',
            scale:1.5
        })
        moveLogo.to('.cp-oeuvre .intro_logo', {
            x:'-120%',
            ease: 'linear'
        })

    }

    let frameNumber = 0;
    const video = document.querySelector('video');
    if(!video){ return; }
    video.play();
    setTimeout(() => {
        video.pause();
    },100)

    let a = gsap.timeline({
        scrollTrigger:{
            trigger: video,
            start: "top 10%",
            end: "+="+(winHeight*4),
            scrub:true,
        }
    });
    a.fromTo( video,{
        currentTime : 0
    },{
        currentTime : 67
    })
}
export default cpOeuvre;

