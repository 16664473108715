import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function cpCircles(winHeight) {
    document.fonts.ready.then(function () {
        let isMobile = false;
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            isMobile = true;
        }


        let pinStart = (winHeight / 2 - 200);
        if (isMobile) {
            pinStart = 60;
        }

        addEventListener("resize", (event) => {
            pinStart = (winHeight / 2 - 200);
            if (isMobile) {
                pinStart = 60;
            }
        });

        let tlCircles = gsap.timeline({
            scrollTrigger: {
                trigger: '.cp-circles',
                toggleActions: 'play pause play reverse',
                start: 'top 80%',
                end: '+=' + (window.innerHeight / 2),
                scrub: 1
            },
        });

        tlCircles.add('animCircles')
            .fromTo('.circle-left', {
                x: '-50vw'
            }, {
                x: 0
            }, 'animCircles').fromTo('.circle-right', {
            x: '50vw'
        }, {
            x: 0
        }, 'animCircles');


        if (!isMobile) {
            let animCirclePink = gsap.timeline({
                scrollTrigger: {
                    trigger: '.cp-circles',
                    toggleActions: 'play pause play reverse',
                    start: 'top 0%',
                    end: 'bottom 100%',
                    scrub: 1,
                    pin: ".pin-circles"
                }
            });

            let circleSize = "150vw";
            if (isMobile) {
                circleSize = "250vw";
            }
            animCirclePink.add('animLeft')
            // LEFT
            animCirclePink.to('.hoverCircle', {
                alpha: 1,
                scale: 1,
                duration: 0.1,
                transformOrigin: 'center'
            }, 'animLeft')
                .to('.circle-left span',
                    {
                        borderColor: 'transparent',
                        duration: 0.1
                    }, 'animLeft')
                .to('.circle-left span',
                    {
                        borderColor: 'white',
                        duration: 0.1,
                        delay: 0.4
                    }, 'animLeft')
                .to('.hoverCircle', {
                    left: '60%',
                    duration: 0.2,
                    delay: 0.2
                }, 'animLeft')
                .to('.circle-right span',
                    {
                        borderColor: 'transparent',
                        duration: 0.1,
                        delay: 0.2
                    }, 'animLeft')
                .to('.circle-right span',
                    {
                        borderColor: 'white',
                        duration: 0.1,
                        delay: 0.6
                    }, 'animLeft')
                .to('.hoverCircle', {
                    width: circleSize,
                    height: circleSize,
                    left: '50%',
                    backgroundColor: 'white',
                    filter: 'blur(50px)',
                    duration: 0.5,
                    delay: 0.6
                }, 'animLeft');

        }
    })
}

export default cpCircles;
