import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function initMenu(){

    const btn = document.querySelector('.btn-mobile');
    btn.addEventListener('click', function(){
        document.querySelector('.sub-menu').classList.toggle('opened')
    });


}

export default initMenu;
