import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function initAbout() {
   /* openEgg();
    closeEgg();
    */

    let btnsAbout = document.querySelectorAll('.linkAbout');
    btnsAbout.forEach((btnAbout) => {
        btnAbout.addEventListener('click',function(e){
            e.preventDefault();

            window.scroll(0, window.scrollY);

            const doc = document.getElementsByTagName( 'html' )[0];
            doc.classList.toggle("opened");
            if( doc.classList.contains("opened")){
                setTimeout(() => { doc.style.overflow = 'hidden' },700);
            }else{
                doc.style.overflow = 'auto'
            }

        });
    })


    let isMobile = false;
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        isMobile = true;
    }


    if(!isMobile){
        let aboutX = gsap.timeline({
            scrollTrigger: {
                trigger: '.about-inner',
                toggleActions: 'play pause play reverse',
                start: 'top 0%',
                end: "bottom 100%",
                pin : '.sections',
                scroller:'#about',
                scrub: 1
            }
        });
        aboutX.add('aboutTime')
            .fromTo('.sections-inner',{
            x:0
        },{
            x:'-80%',
            ease:'linear'
        },'aboutTime')
            .to('.imgtest',{
            x:'-35%',
            delay:0.20
        },'aboutTime')

        const height = document.querySelector('.sections-inner').offsetWidth;
        document.querySelector(".about-inner").style.height = height+'px';

    }

    function openEgg(){
        const cta = document.querySelector('.tertiodecimo_opere');
        cta.addEventListener('click', function(){
            const target = document.querySelector('#tertiodecimo_opere');
            const video = document.querySelector('#background-video');
            video.play();
            target.style.display = 'block';
            gsap.to('#tertiodecimo_opere > div', {
                alpha:1
            });
        })
    }

    function closeEgg(){
        const cta = document.querySelector('.close-opere');
        cta.addEventListener('click', function(){
            const target = document.querySelector('#tertiodecimo_opere');
            const video = document.querySelector('#background-video');
            video.pause();
            gsap.to('#tertiodecimo_opere > div', {
                alpha:0,
                onComplete: function(){
                    target.style.display = 'none';
                }
            })

        })

    }

}
export default initAbout;
