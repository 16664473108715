import gsap from "gsap";
import ScrollSmoother from '../libs/ScrollSmoother';

gsap.registerPlugin(ScrollSmoother);
function initScroll() {

    window.onbeforeunload = function () {
        window.scrollTo(0, 0);
    }
    window.scrollTo(0,0);
    const menuLinks = document.querySelectorAll('.linkMenu');
    if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {

        let smoother = ScrollSmoother.create({
            smooth: 1,               // how long (in seconds) it takes to "catch up" to the native scroll position
            effects: true,           // looks for data-speed and data-lag attributes on elements
            smoothTouch: 0.1,        // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
        });


        menuLinks.forEach(  (link) => {
            link.addEventListener('click', function(e){
                e.preventDefault();
                smoother.scrollTo(''+link.dataset.anchor+'', true, "top center");
                document.querySelector('.sub-menu').classList.remove('opened')
            })
        })
    }else{
        menuLinks.forEach(  (link) => {
            link.addEventListener('click', function (e) {
                document.querySelector('.sub-menu').classList.remove('opened')
            })

            document.querySelector('.linkAbout').addEventListener('click', function(){
                document.querySelector('.sub-menu').classList.remove('opened')
            })
        })
    }
}

export default initScroll
