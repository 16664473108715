import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {CSSRulePlugin} from 'gsap/CSSRulePlugin';
import {EaselPlugin} from 'gsap/EaselPlugin';

gsap.registerPlugin(ScrollTrigger, CSSRulePlugin, EaselPlugin);


const teqAnimations = () => {


    let tl = null;

    gsap.utils.toArray('.anim-img-scale').forEach((box) => {
        const child = box.querySelector('img')
        tl = gsap.timeline({
            scrollTrigger: {

                trigger: box,
                toggleActions: 'play pause play reverse',
                start: 'top 60%',
            },
        });
        tl.to(child, {
            ease: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
            duration: 1,
            scale: 1,
        });
    });


    gsap.utils.toArray('.anim-img-reveal-bottom').forEach((box) => {

        tl = gsap.timeline({
            scrollTrigger: {

                trigger: box,
                toggleActions: 'play pause play reverse',
                start: 'top 90%',
                toggleClass: 'show'
            },
        });

        tl.to(box.querySelector('.overlay'), {
            duration: 1,
            scaleY: 0
        });
    });

    // IMGS REAVEAL WITH CHILDS DELAY
    gsap.utils.toArray('.anim-childs-reveal').forEach((box) => {

        var d = 0;
        var items = box.querySelectorAll('.anim-img-reveal-bottom-delay');

        items.forEach(item => {
            tl = gsap.timeline({
                scrollTrigger: {
                    trigger: box,

                    toggleActions: 'play pause play reverse',
                    start: "top 90%"
                }
            });
            tl.to(item.querySelector('.overlay'), {
                duration: 0.4,
                scaleY: 0,
                delay: d
            });

            d = d + 0.1;
        });

    });


    // ANIM SLIDE TO LEFT WITH OPACITY

    gsap.utils.toArray('.anim-to-left').forEach((box) => {
        tl = gsap.timeline({
            scrollTrigger: {

                trigger: box,
                toggleActions: 'play pause play reverse',
                start: 'top 80%',
            },
        });

        tl.to(box, {
            x: 0,
            alpha: 1
        })

    });


    gsap.utils.toArray('.anim-to-top').forEach((box) => {
        tl = gsap.timeline({
            scrollTrigger: {

                trigger: box,
                toggleActions: 'play pause play reverse',
                start: 'top 80%',
            },
        });

        tl.to(box, {
            y: 0,
            alpha: 1
        })

    });

    gsap.utils.toArray('.anim-txt-to-top').forEach((box) => {
        var child = box.querySelector('.child');
        if(box.classList.contains('async')){ return; }
        tl = gsap.timeline({
            scrollTrigger: {

                trigger: box,
                toggleActions: 'play pause play reverse',
                start: 'top 80%',
            },
        });

        tl.to(child, {
            y: 0,
            alpha: 1
        })

    });


    gsap.utils.toArray('.anim-txt-to-left').forEach((box) => {
        var child = box.querySelector('.child');

        if(box.classList.contains('async')){ return; }
        tl = gsap.timeline({
            scrollTrigger: {

                trigger: box,
                toggleActions: 'play pause play reverse',
                start: 'top 80%',
            },
        });

        tl.to(child, {
            x: 0,
            alpha: 1
        })

    });




}
export default teqAnimations;
