import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
const arrColors = new Array('#97FAF3','#D9FDB9', '#FDB4CA', '#DDD0CC', '#82F2FC', '#CFB4FC', '#B1B22A', '#8696FD', '#E43337', '#E8B860', '#C6FAC1', '#FCB8D1');

function initNft() {

    const arr = arrColors;
    const urlHash = window.location.hash;
    if (arr.includes(urlHash.toUpperCase())) {
        loadDoc('nft/detail.php', window.location.hash);
    }

    // LOAD PAGE
    const btnsOpen = document.querySelectorAll('.open-nft');
    btnsOpen.forEach( (btn) => {
        btn.addEventListener('click', (e) => {
            e.preventDefault();
            const color = btn.dataset.color;
            const link = btn.href;
            loadDoc(link, color);
            const menuNfts = document.querySelector('#menu_nfts');
            if(menuNfts.style.display === 'block'){
                hideMenuNft(menuNfts);
            }
        })
    })


    function loadDoc(link, color) {

        const inner =  document.getElementById("nft");
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function() {
            if (this.readyState == 4 && this.status == 200) {
                // INJECT CONTENT & SHOW IT
                inner.innerHTML = this.responseText;
                inner.style.display = 'block';
                // transition right
                gsap.to(inner,{
                    x: 0,
                    duration:1,
                    ease: "power2.out"
                });

                // SET COLOR CONTENT
                color = color.toLowerCase();
                const doc = document.getElementsByTagName( 'html' )[0];
                document.documentElement.style.setProperty('--nft', color);
                doc.querySelector('.title-nft').textContent = color;

                let btnNav = document.querySelector('.btn-nav');
                btnNav.dataset.color = color;
                var video = doc.querySelector('.nft-video');
                video.querySelector('source').src = './assets/images/nft/videos/le_saut_uni_'+color.replace('#','')+'.webm';
                video.load();

                var cover = doc.querySelector('.cover-video');
                cover.src = './assets/images/nft/le_saut_'+color.replace('#','')+'.jpg';

                // remove document scroll
                setTimeout(() => { doc.style.overflow = 'hidden' },300);

                // run actions functions
                updateMintedNfts(color);
                playVideo();
                closeNft();
                openNftMenu();

                openWallet();
                closeWallet();
                setIdNft();
            }
        };

        xhttp.open("POST", link, true);
        xhttp.send();
    }


    // PLAY VIDEO
   function playVideo(){
       let btnPlay = document.querySelector('.play-nft');
       btnPlay.addEventListener('click', () => {
           const p = btnPlay.closest('.video-container');
           const video = p.querySelector('.nft-video')
           const cover = p.querySelector('.cover-video');
           const player = p.querySelector('.player');

           gsap.to(cover, {
               alpha:0
           })
           gsap.to(player, {
               alpha:0,
               onComplete: function(){
                   p.classList.add('playing');
                   video.play();
               }
           })

       });

   }

   function closeNft(){
       const doc = document.getElementsByTagName( 'html' )[0];
       const inner =  document.getElementById("nft");
       let btnsClose = document.querySelectorAll('.close-nft');
       const video = inner.querySelector('.nft-video')

       btnsClose.forEach((btn) => {
           btn.addEventListener('click', () => {
               video.pause();
               gsap.to(inner,{
                   x: '100%',
                   duration:1,
                   ease: "power2.out",
                   onComplete:function(){
                       inner.style.display = 'none';
                       doc.style.overflow = 'auto'
                   }
               });
           })
       })

   }


   function openNftMenu(){
        const btn = document.querySelector('.open-nft-menu');
        const menu = document.querySelector('#menu_nfts');
        btn.addEventListener('click', function(){
            menu.style.display = 'block';
            gsap.to(menu,{
                alpha:1
            })
        });
   }

   closeNftMenu();
   function closeNftMenu(){
        const btn = document.querySelector('.close-menu_nfts');
       const menu = document.querySelector('#menu_nfts');
       btn.addEventListener('click', function(){
           hideMenuNft(menu)
       })
   }
   function hideMenuNft(menu){
       gsap.to(menu,{
           alpha:0,
           onComplete:function(){
               menu.style.display = 'none';
           }
       })
   }


   function openWallet(){
       const btn = document.querySelector('#buyNft');
       btn.addEventListener('click', function(e){
           e.preventDefault();

           const popup = document.querySelector('#root');
           popup.style.display = 'block';
           let tl = gsap.timeline();
           tl.to(popup, {
               backgroundColor: 'rgba(0,0,0,0.6)'
           }).to('.App',{
               alpha:1,
               scale:1
           })
       })


   }

   function closeWallet(){
       const btn = document.querySelector('.cross');
       btn.addEventListener('click', function(){
           localStorage.removeItem('idNft')
           const popup = document.querySelector('#root');
           gsap.to('.App',{
               alpha:0,
               scale:0.5
           })
               gsap.to(popup, {
               backgroundColor: 'transparent',
               onComplete: function(){
                   popup.style.display = 'none';
               }
           })


       })
   }

   function setIdNft(){
       const btn = document.querySelector('#buyNft');
       btn.addEventListener('click', function(e) {
           e.preventDefault();
           const arr = new Array('#97FAF3', '#D9FDB9', '#FDB4CA', '#DDD0CC', '#82F2FC', '#CFB4FC', '#B1B22A', '#8696FD', '#E43337', '#E8B860', '#C6FAC1', '#FCB8D1');
           let datasetSelected = btn.dataset.color;
           let selectIndex = arr.indexOf(datasetSelected.toUpperCase());
           localStorage.setItem('idNft', selectIndex+1)
       })

   }

   function updateMintedNfts(color){
       let localS = localStorage.getItem('ArrMintedNfts');
       let arr = localS.slice(1, -1)
       var arrMintedId = arr.split(',').map(function(item) {
           return parseInt(item, 10);
       });
       let arrMintedColors = new Array();
       arrMintedId.forEach( index => {
           arrMintedColors.push(arrColors[index])
       });
       if (arrMintedColors.includes(color.toUpperCase())) {
           const btn = document.querySelector('#buyNft');
           btn.textContent = 'Minted';
           btn.classList.add('minted');
       }
   }



}
export default initNft
