import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

import toggleGrid from "./helpers/_toggleGrid";
import initVh from "./helpers/_initvh";
import initScroll from "./modules/initScroll";

import initNoise from "./modules/initNoise"
import teqAnimations from "./helpers/_animations";
import cpIntro from "./modules/intro";
import cpReading from "./modules/cpReading";
import cpCircles from "./modules/cpCircles";
import cpOeuvre from "./modules/cpOeuvre";
import cpGallery from "./modules/cpGallery";
import initAccordion from "./modules/initAccordion";
import initAbout from "./modules/about";
import initNft from "./modules/initNft";
import initMenu from "./modules/initMenu";

document.addEventListener('DOMContentLoaded', () => {


    let winHeight = window.innerHeight;

        function resizedw(){
            if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                winHeight = window.innerHeight;
                window.scrollTo(0, 0);
            }
            ScrollTrigger.refresh();
        }

        var doit;
        window.onresize = function(){
            clearTimeout(doit);
            doit = setTimeout(resizedw, 100);
        };



    toggleGrid();
    initVh();
    initScroll();
    initNoise();
    teqAnimations();
    cpIntro(winHeight);
    cpCircles(winHeight);
    cpOeuvre(winHeight);
    cpGallery(winHeight);
    initAccordion(winHeight);
    cpReading();
    initNft();
    initMenu();

    initAbout();

});

